import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Container,
  Section,
  Heading,
  Subhead,
} from "../components/ui"

interface ContactProps {
  data: {
    aboutPage: {
      id: string
      title: string
      description: string
      image: { id: string; url: string }
    }
  }
}

export default function Thanks(props: ContactProps){
    const { aboutPage } = props.data
    return (
    <Layout {...aboutPage}>
      <Section>
        <Container>
          <Heading>Thank you!</Heading>
          <Subhead>We will be in contact soon</Subhead>
          <div style={{height: "550px"}}></div>
        </Container>
      </Section>
    </Layout>
    )
}

export const query = graphql`
  {
    thankYouPage {
      id
      title
      description
      image {
        id
        url
      }
    }
  }
`
